import style from './navigation.module.scss'

export default function Navigation() {
  return (
    <div className={style.navigation}>
      <button className="btn-cssbuttons">
        <span>渔网</span><span>
          <svg height="18" width="18" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1024 1024" className="icon">
            <path fill="#ffffff" d="M767.99994 585.142857q75.995429 0 129.462857 53.394286t53.394286 129.462857-53.394286 129.462857-129.462857 53.394286-129.462857-53.394286-53.394286-129.462857q0-6.875429 1.170286-19.456l-205.677714-102.838857q-52.589714 49.152-124.562286 49.152-75.995429 0-129.462857-53.394286t-53.394286-129.462857 53.394286-129.462857 129.462857-53.394286q71.972571 0 124.562286 49.152l205.677714-102.838857q-1.170286-12.580571-1.170286-19.456 0-75.995429 53.394286-129.462857t129.462857-53.394286 129.462857 53.394286 53.394286 129.462857-53.394286 129.462857-129.462857 53.394286q-71.972571 0-124.562286-49.152l-205.677714 102.838857q1.170286 12.580571 1.170286 19.456t-1.170286 19.456l205.677714 102.838857q52.589714-49.152 124.562286-49.152z"></path>
          </svg>
        </span>
        <ul>
          <li title='github'>
            <a href="https://github.com/HeKvn">
              <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="945" width="20" height="20">
                <path d="M512 42.666667A464.64 464.64 0 0 0 42.666667 502.186667 460.373333 460.373333 0 0 0 363.52 938.666667c23.466667 4.266667 32-9.813333 32-22.186667v-78.08c-130.56 27.733333-158.293333-61.44-158.293333-61.44a122.026667 122.026667 0 0 0-52.053334-67.413333c-42.666667-28.16 3.413333-27.733333 3.413334-27.733334a98.56 98.56 0 0 1 71.68 47.36 101.12 101.12 0 0 0 136.533333 37.973334 99.413333 99.413333 0 0 1 29.866667-61.44c-104.106667-11.52-213.333333-50.773333-213.333334-226.986667a177.066667 177.066667 0 0 1 47.36-124.16 161.28 161.28 0 0 1 4.693334-121.173333s39.68-12.373333 128 46.933333a455.68 455.68 0 0 1 234.666666 0c89.6-59.306667 128-46.933333 128-46.933333a161.28 161.28 0 0 1 4.693334 121.173333A177.066667 177.066667 0 0 1 810.666667 477.866667c0 176.64-110.08 215.466667-213.333334 226.986666a106.666667 106.666667 0 0 1 32 85.333334v125.866666c0 14.933333 8.533333 26.88 32 22.186667A460.8 460.8 0 0 0 981.333333 502.186667 464.64 464.64 0 0 0 512 42.666667" fill="#ffffff" p-id="946"></path>
              </svg>
            </a>
          </li>
          <li title='博客'>
            <a href="https://hekvn.top/blog/">
            <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="915" width="20" height="20">
              <path d="M947.701961 623.435294l-694.713725 0q-11.043137 0-23.090196 6.023529t-22.588235 18.070588-17.066667 28.611765-6.52549 37.647059 5.521569 39.152941 14.556863 32.12549 22.086275 22.086275 28.109804 8.031373l693.709804 0q-12.047059-12.047059-21.082353-27.105882-8.031373-13.05098-14.556863-31.121569t-6.52549-41.160784q0-26.101961 6.52549-43.168627t14.556863-28.109804q9.035294-12.047059 21.082353-21.082353zM947.701961 897.505882q0 17.066667-22.086275 17.066667l-733.866667 0q-34.133333 0-57.223529-14.054902t-37.647059-38.65098-20.580392-58.729412-6.023529-73.286275l0-582.27451q0-77.301961 41.160784-111.937255t117.458824-34.635294l34.133333 0 68.266667 0 93.364706 0q51.2 0 104.909804-0.501961t104.909804-0.501961l94.368627 0 70.27451 0 35.137255 0q28.109804 0 49.192157 11.545098t35.137255 32.12549 21.584314 48.690196 7.529412 60.235294l0 406.588235q-191.74902 0-345.34902-1.003922l-128.501961 0q-64.25098 0-114.94902-0.501961t-83.827451-0.501961l-37.145098 0q-21.082353 0-41.160784 12.54902t-36.141176 34.133333-26.101961 50.196078-10.039216 59.733333q0 38.14902 10.541176 68.768627t27.607843 51.701961 37.647059 32.627451 40.658824 11.545098l685.678431 0q8.031373 0 14.556863 4.517647t6.52549 14.556863zM515.011765 0l0 388.517647 77.301961-108.423529 76.298039 108.423529 0-388.517647-153.6 0z" p-id="916" fill="#ffffff"></path>
            </svg>
            </a>
          </li>
          <li title='npm'>
            <a href="https://www.npmjs.com/~hekvn">
              <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="945" width="20" height="20">
                <path d="M0 0v1024h1024V0H0z m832 832h-128V320h-192v512H192V192h640v640z" p-id="946" fill="#ffffff"></path>
              </svg>
            </a>
          </li>
        </ul>
      </button>
    </div>
  )
}
